import React from "react";
import profile_pic from "../../Assets/profile_pic.jpeg"
import { project_data } from "../../data/data";

function Home() {

  const Card = ({ item }) => {
    return(
      <div className="project-card" onClick={ () => window.open(item.link)}> 
        <div >
          <text className="card-title">{item.title}</text>
        </div>
        <img src={item.img} className="img"/>
        <div >
          <text className="card-title">{item.description}</text>
        </div>
      </div>
    )
  }

  const TextLink = ({link, text}) => {
    return(
      <text> 
        &nbsp; 
        <text className="link-text" onClick={() => window.open(link)}>{text}</text>    
      </text>
    )
  }

  const link80_20 = "https://blog.mailmanhq.com/how-to-use-the-pareto-principle-80-20-rule-to-succeed-in-your-life/#:~:text=80%25%20of%20the%20value%20of,you%20without%20any%20selfish%20benefit."

  return (
    <section className="home-header" >
      {/* <div> */}

      {/* <h4> */}
      <div className="home-intro">
        <h1 className="heading-name">Hi. I'm
          <strong className="main-name"> Andy</strong>
        </h1> 
        <div className="home-ctn1">
          <p className="home-about-body">
            I value individuals based on their kindness and how they treat others.
          </p>
        </div>
      </div>

        
      {/* </h4> */}
      {/* <h5>
        <text className="main-white">MBA x Bitotech x Startup</text>
      </h5> */}
      <div className="project-container">
        {
          project_data.map((item, i) => (
            <Card item={project_data[i]} />
          ))
        }
      </div>
    </section>
  );
}

export default Home;
