import lbLogo from "../Assets/Projects/lb.png"
import magic from "../Assets/Projects/magic.png"
import github from "../Assets/Projects/github.png"
import lstm from "../Assets/Projects/lstm-stock.png"
import cell from "../Assets/Projects/cell.png"
import medium from "../Assets/Projects/medium.png"
import travel from "../Assets/Projects/travel.png"
import vienna from "../Assets/Projects/vienna.png"
import backlight from "../Assets/Projects/backlight.png"
import me from "../Assets/Projects/me.png"
import snow from "../Assets/Projects/snow.png"

export const project_data = [
        {
                title: "I travel, and take photos",
                description:"on Sony ZV E10" ,
                link:"https://www.instagram.com/ctifihylt/",
                img: travel
        },
        {
                title: "I write",
                description:"some random thoughts" ,
                link:"https://197andy.medium.com/reminder-to-myself-with-great-power-comes-great-responsibility-2f7a8305c6f8",
                img: backlight
        },
        // {
        //         title: "Basketball Magic",
        //         description:"100k+ YouTube views",
        //         link:"https://www.youtube.com/watch?v=R9oZRiKPQmA&t=1s",
        //         img: magic
        // },
        {
                title: "Favorite",
                description:"Performance",
                link:"https://www.youtube.com/watch?v=dQw4w9WgXcQ",
                img: vienna
        },
        {
                title: "IG",
                description:"a lot of ____",
                link:"https://www.instagram.com/19705_andy/",
                img: snow
        },
        {
                title: "Work stuffs",
                description:"If interested",
                link:"https://teyenchou.com/",
                img: me
        },
]

export const portfolio_data = [
        {
                title: "Linking Book",
                description:"Linking Book is a mobile platform for people share to what they read, and is designed to solve misinformation problem ." ,
                link:"https://github.com/19705cty/linking-book",
                img: lbLogo
        },
        {
                title: "LSTM Stock prediction",
                description:"This project use LSTM RNN to forecast stock price. I am working on a more details and maybe friendly user interface!" ,
                link:"https://github.com/19705cty/LSTM-stock-predict",
                img: lstm
        },
        {
                title: "GitHub",
                description:"Go GitHub and see more projects....",
                link:"https://github.com/19705cty",
                img: github
        },
]

export const magic_imgs = [
        {
                img:"https://andy-website-image.s3.us-east-2.amazonaws.com/IMG_3735.JPG"
        },
        {
                img:"https://andy-website-image.s3.us-east-2.amazonaws.com/IMG_2523.JPG"
        },
        {
                img:"https://andy-website-image.s3.us-east-2.amazonaws.com/IMG_2698.JPG"
        },
        {
                img:"https://andy-website-image.s3.us-east-2.amazonaws.com/basketball.png"
        },
        // {
        //         img:
        // },
]

export const magic_profile = "https://andy-website-image.s3.us-east-2.amazonaws.com/14481798_1159521720796821_8125915069111156951_o.jpeg"